import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Prayer = () => (
  <Layout>
    <SEO title="Prayer" />
    <div className="container content">
      <h1>Prayer</h1>
      <h2>Join a nearby Prayer Group in your State</h2>
      Please contact the following State Representatives.
      <h4 className="mt-3">NSW/Sydney : </h4>
      Reggie Abel - 0412 540 270 <br /> Joanna Warren - 0437 139 961
      <h4>QLD :</h4>
      Gold Coast / 3rd Saturday @ 2.30pm <br /> Rex Christopher - 0433 336 700 <br /> Dr. Koshy George - 0459 988 250
      <h4>VIC :</h4>
      Charles Wesley - 0449 103 077 - vic@fiema.org
      <h4>WA:</h4>
      Gosnells/Perth - John Abraham - 0434 268 622 - John_abh@hotmail.com  <br /> Balga/Perth - Bill Taylor - 0447 150 630
      <h4>Brisbane/Westside</h4>
      1st Saturday @ 2:30pm<br />
      Sheela Neredumilli - 0433 950 282<br />
      Mercy Talluri - 0425 656 815
      <h4>Brisbane/Northside</h4>
      4th Saturday @ 3:00 pm <br />
      Dr. Thumma Reddy - 07 3191 1760
      <h4>Brisbane/ Southside</h4>
      Last Saturday @ 8:00 am<br />
      Vincent & Elizabeth - 0424 396 096<br />
      Philip Patlur  - 0437 400 009 - national.director@fiema.org

    </div>
  </Layout>
)

export default Prayer
